@charset "utf-8";

// First let's import all boilerplate code
@import "setup/vars";
@import "setup/mixins";
@import "setup/normalize";
@import "setup/helpers";
@import "setup/boilerplate";
@import "setup/burger";
//@import "setup/fonts";
@import url('https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900&subset=hebrew&display=swap');

html, body {
    height:100%;
    min-width:1480px;
}

body{
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    font-family:$default-font-family;
    
    &.fixed{
        overflow:hidden;
    }
}


.desktop-only{
    display:block;
}

.mobile-only{
    display:none;
}

.content{
    padding-top:149px;
}

.mobile-nav{
    display: none;
}

.main-nav{
    background:white;
    position: fixed;
    top:0;
    width: 100%;
    text-align: center;
    font-size: 0;
    z-index:500;

    .top{
        padding:20px 30px;
        box-sizing:border-box;
        border-bottom: #f3f3f3 1px solid;
        position: relative;
        height:91px;
        
        .logo{
            height: 50px;
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            left: 50%;
            margin-left: -170.42px;
        }
        
        .right{
            padding: 6px 0;
            display:flex;
            text-align: left;
            vertical-align: middle;
            float: left;
            .login-btn{
                display:inline-block;
                border-radius:4px;
                background:$dark-blue;
                color:white;
                font-size:13px;
                height:39px;
                font-weight: $bold;
                text-align: center;
                padding: 12px 15px;
                cursor:pointer;
                margin-left:10px;
                vertical-align: middle;
                @include transition(all 0.2s linear);
                
                &:hover{
                    background:lighten( $dark-blue, 10% );
                }
            }
            
            .subscribe-btn{
                display:inline-block;
                border-radius:4px;
                background:#88c0d2;
                color:white;
                font-size:13px;
                height:39px;
                font-weight: $medium;
                text-align: center;
                padding: 12px 15px;
                cursor:pointer;
                margin-left:10px;
                vertical-align: middle;
                @include transition(all 0.2s linear);
                
                &:hover{
                    background:$light-blue;
                }
            }
            
            .search-box{
                display:flex;
                justify-content: space-between;
                border:2px solid #ebebeb;
                border-radius:4px;
                vertical-align: middle;
                max-height: 40px;
                input{
                    font-size:13px;
                    border:0;
                    color:$grey;
                    outline:0;
                    width:190px;
                    padding-right:4px;
                    height:35px;
                    vertical-align: middle;
                    @include transition(all 0.3s linear);
                }
                
                img{
                    width: 35px;
                    height: auto;
                    display: inline-block;
                    vertical-align: middle;
                    padding: 10px;
                }
            }
        }
        
        .left{
            padding: 15px 0px 11px 0px;
            float: right;
            display: inline-block;
            text-align: right;
            vertical-align: middle;
            
            img{
                height:24px;
                margin-left:30px;
                width:auto;
            }

        }
    }
    
    .bottom{
        padding: 0px 30px;
        box-sizing:border-box;
        position: relative;
        text-align: center;
        
        .right{
            display: inline-block;
            vertical-align: middle;
            width:150px;
            text-align: right;
            #INDbtnWrap{position:relative;}
            #INDmenu-btn {
                top: 0;direction: rtl;float: none; right: 0; position: relative;display:none;
            }
            #INDbtnTooltip {top: 2px!important; right: 108px!important;}
            div .negishut{
                cursor: pointer;
                @include filter(grayscale,100%);
                @include transition(all 0.2s linear);

                img{
                    height:30px;
                    width:auto;
                }
                
                p{
                    font-size:13px;
                    display:inline-block;
                    vertical-align: middle;
                    color:#0781c4;
                    font-weight:$medium;
                    padding-right:6px;
                }
                
                &:hover{
                    @include filter(grayscale,0%);
                }
            }
        }
        
        .center{
            display:inline-block;
            @include calc(width, '100% - 300px');
            text-align: center;
            vertical-align: middle;

            ul{
                list-style: none;
                padding:0;

                li{
                    font-size: 16px;
                    color:$light-blue;
                    display:inline-block;
                    margin:0 20px;
                    position:relative;
                    padding: 0 10px;
                    line-height: 58px;    

                    &:before{
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        margin-left: 0;
                        width: 0%;
                        height: 4px;
                        opacity:0;
                        @include linear-gradient(to right, #76c5d7 0%, #3596b5 100%);
                        @include transition(all 0.2s ease);
                    }
                    
                    &:hover{
                        
                        &:before{
                            width: 100%;
                            opacity:1;
                            margin-left: -50%;
                        }
                    }
                    
                    &.current-menu-item{
                        &:before{
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            width: 100%;
                            opacity:1;
                            margin-left: -50%;
                            height: 4px;
                            @include linear-gradient(to right, #76c5d7 0%, #3596b5 100%);
                            @include transition(all 0.2s ease);
                        }
                    }
                }
            }
        }
        
        .left{
            display: inline-block;
            vertical-align: middle;
            width:150px;
            text-align: left;
            
            a{
                color:$light-blue;
                font-size:13.5px;
                font-weight:$medium;
                @include transition(all 0.2s linear);
                
                &:hover{
                    color: $dark-blue;
                }
            }
        }
    }
}


.contact-section{
    height:auto;
    width:100%;
    font-size:0;
    margin:0 auto;
    background:url('../img/contactbg.jpg') center center no-repeat;
    background-size:cover;
    
    
    
    .container{
        width:100%;
        height:100%;
        margin: 0 auto;
        max-width:1200px;
        text-align: right;
        font-size: 0;
        padding:20px;
        box-sizing: border-box;
        
        .contact-btn{
            display:none;
        }
        
        .contact-box{
            width: 580px;
            height: 100%;
            display: inline-block;
            text-align: center;
            padding: 0 58px;
            font-size:0;
            box-sizing: border-box;
            
            h2{
                font-size:50px;
                color:white;
                font-weight: $regular;
                margin-bottom:10px;
                margin-top: 0;
            }
            
            h4{
                font-size:18px;
                color:white;
                font-weight: $regular;
                margin:10px 0;
                margin-bottom: 20px;
            }
            
             .form{
                text-align: right;

                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                  color: #757575;
                }
                ::-moz-placeholder { /* Firefox 19+ */
                  color: #757575;
                }
                :-ms-input-placeholder { /* IE 10+ */
                  color: #757575;
                }
                :-moz-placeholder { /* Firefox 18- */
                  color: #757575;
                }
                
                .field{
                    display: inline-block;
                    margin-bottom: 8px;
                    vertical-align: top;
                    width:228px;
                    .error{
                        font-size:14px;
                        text-align: center;
                        color:white;
                        margin-bottom:5px;
                        display: none;
                    }
                    
                    &.has-error{
                        input, textarea, select{
                            background-color:#ffbfbf;
                        }
                        
                        .error{
                            display: block;
                        }
                    }
                    
                    &.full-width{
                        width:100%;    
                    }
                    
                    &.spaced{
                        margin-left:8px;
                    }
                }
                
                input{
                    font-size:16px;
                    color:$grey;
                    width:100%;
                    font-weight: $medium;
                    padding:17px;
                    box-sizing: border-box;
                    border:0;
                    border-radius: 3px;
                    outline: 0!important; 
                }
                
                textarea{
                    font-size:16px;
                    color:$grey;
                    width:100%;
                    font-weight: $medium;
                    padding:17px;
                    box-sizing: border-box;
                    border:0;
                    border-radius: 3px;
                    outline: 0!important; 
                    resize: none;
                    
                }
                
                select::-ms-expand {
                    display: none;
                }
                 
                select{
                    font-size:16px;
                    color:$grey;
                    width:100%;
                    font-weight: $medium;
                    padding:17px;
                    box-sizing: border-box;
                    border:0;
                    border-radius: 3px;
                    outline: 0!important; 
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    background:url('../img/select.svg') no-repeat, white;
                    background-position: 15px 50%;
                    background-size: 13px 8px;
                    
                    .placeholder{
                        display:none;
                    }
                }
                 
                 .submit-btn{
                     display: block;
                     max-width:194px;
                     margin:0 auto;
                     margin-top:4px;
                     color:white;
                     border-radius: 3px;
                     text-align: center;
                     font-size:18px;
                     font-weight: $bold;
                     background:#005172;
                     line-height: 52px;
                     cursor: pointer;
                     @include transition(all 0.2s ease);

                     &:hover{
                         background: #0070a4;
                     }
                 }
            }
        }
    }
    
}

.logos-container{
    width:100%;
    max-width:1200px;
    margin:0 auto;
    text-align: center;
    font-size:0;
    
    .item{
        
        display:inline-block;
        vertical-align: top;
        text-align: center;
        padding:0 27px; 
        height:112px;
        margin: 40px 0;
        border-left: 1px solid #dedede;
        
        &:first-child{
        }
        
        &:last-of-type{
            /**padding-left:0;***/
            border-left: 0px solid #dedede;

        }
        
        .logo{
            margin:0 auto;
            width: 113px;
            height: 76px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
        
        h3{
            color:$grey;
            opacity:0.5;
            margin:0 auto;
            margin-top:10px;
            font-size:12px;
            font-weight:normal;
        }
    }
}

.login-bar{
    border-top: 2px solid #dedede;
    border-bottom: 2px solid #dedede;
    text-align: center;
    font-size:0;
    overflow: hidden;
    
    .container{
        max-width:1169px;
        margin:0 auto;
    }
    
    .third{
        width:33.33%;
        display: inline-block;
        vertical-align: middle;
        
        p{
            color:$light-blue;
            font-size: 14px;
            line-height: 49px;
        }
        
        &.right{
            padding: 20px 0;
            float:right;
            display:inline-block;
            text-align: right;
            vertical-align: middle;
            
            .login-btn{
                display:inline-block;
                border-radius:4px;
                background:$dark-blue;
                color:white;
                font-size:13px;
                height:39px;
                font-weight: $bold;
                text-align: center;
                padding: 12px 15px;
                cursor:pointer;
                margin-left:10px;
                vertical-align: middle;
                @include transition(all 0.2s linear);
                
                &:hover{
                    background:lighten( $dark-blue, 10% );
                }
            }
            
            .subscribe-btn{
                display:inline-block;
                border-radius:4px;
                background:#88c0d2;
                color:white;
                font-size:13px;
                height:39px;
                font-weight: $medium;
                text-align: center;
                padding: 12px 15px;
                cursor:pointer;
                margin-left:10px;
                vertical-align: middle;
                @include transition(all 0.2s linear);
                
                &:hover{
                    background:$light-blue;
                }
            }
        }
        
        &.left{
            padding: 24px 0;
            float:left;
            display:inline-block;
            text-align: left;
            vertical-align: middle;
            color:$light-blue;
            font-size:32px;
            
            a{
                vertical-align: middle;
                margin-right:20px;
            }
        }
    }
            
}

.sitemap{
    text-align: center;
    max-width:1200px;
    margin: 0 auto;
    padding-top: 24px;
    display: flex;
    .col{
        text-align: right;
        width:240px;
        margin-left:76px;
        display: inline-block;
        margin-bottom:20px;
        vertical-align: top;
        
        /*&:first-child{
            width:188px;
        } */  
        
        &:last-child{
            margin-left:0;
        }
        
        h3{
            color:$dark-blue;
            font-weight:$medium;
            font-size:18px;
            padding:15px 0;
            border-bottom: 2px solid #dedede;
            margin-top:0;
        }
        
        ul{
            list-style: none;
            padding: 0;
            border-bottom: 1px solid #dedede;
            padding-bottom: 14px;
            margin-bottom:14px;
            
            &.no-div{
                border-bottom: 0;
                margin-bottom: 0;
            }
            
            &:last-of-type{
                border-bottom: 0;
            }
            
            li{
                color:$light-blue;
                font-size:14px;
                line-height: 2;
                @include transition(all 0.2s linear);
                
                &:hover{
                    color:lighten( $light-blue, 10% );
                }
                
                &.parent{
                    color:$dark-blue;
                    
                    &:hover{
                        color:lighten( $dark-blue, 10% );
                    }

                }
            }
        }
        
        .iso{
            opacity:0.6;
            margin-top:10px;
            @include transition(all 0.3s linear);
                
                &:hover{
                }
        }
        
    }
}


.sitemap-mobile{
    display:none;
}

.mobile-bottom{
    display:none;
}

.mobile-menu{
    display:none;
}

.pop-thanks-wrapper{
    position: fixed;background: rgba(90, 90, 90, 0.8);top: 0;right: 0;width: 100%;height: 100%;z-index: 9999;
     display: none; opacity: 1; 
    .thanks-pop{
        position: absolute; width: 800px; right: 50%; margin-right: -400px; top: 13%; background: white;
        @include box-shadow(0px, 2px, 20px, rgba(0, 0, 0, 0.1));
        font-size: 0;
        .close-btn{ 
            position: absolute; font-size: 24px; color: #656565; top: 10px;
             left: 15px; z-index: 10; cursor: pointer;
        }
        .thanks-pop-content{    width: 100%; display: inline-block; height: 360px; padding-top: 90px;
    box-sizing: border-box; text-align: center;
            h1{ font-size: 34px; color: $dark-blue; font-weight: 500;  margin-top: 20px;}
        
        }
    }

}

.main-nav .top{
    &.top-sml{
        display:none;
    }
}


.about-author {
    display: flex;
    padding: 20px 0;
    .about-author-image {
        img {
            border-radius: 50%;
            max-width: 75px;
            height: auto;
        }
    }
    .about-author-text {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        a {
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 1500px) {
    
    .content{
        overflow-x: hidden;
    }
    
    .main-nav{
        .top{
            display:none;
            
            &.top-sml{
                display:block;
                
                .logo{
                    height: 38px;
                    margin-left: -129.5px;
                    margin-top: 3px;
                }
                
                .right{
                    .search-box{
                        input{
                            width: 136px;
                        }
                    }
                }
            }
        }
        
        
        .bottom {
            .right {
                width:90px;

                #INDbtnTooltip {
                    top: 13px !important;
                    right: 128px !important;
                    z-index: 20;
                }
                
                #INDmenu-btn{
                    p{
                        display:none;
                    }
                }
                
            }

            .center{
                width: -moz-calc(100% - 180px);
                width: -webkit-calc(100% - 180px);
                width: calc(100% - 180px);

                ul{
                    li{
                        font-size: 16px;
                        color: #4296b4;
                        display: inline-block;
                        margin: 0 10px;
                        position: relative;
                        padding: 0 10px;
                        line-height: 58px;
                    }
                }
            }

            .left{
                width:90px;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    
    html, body{
        min-width:1300px !important;
    }

    .content{
        overflow-x: hidden;
    }
    
    .main-nav{
        .top{
            display:none;
            
            &.top-sml{
                display:block;
                
                .logo{
                    height: 38px;
                    margin-left: -129.5px;
                    margin-top: 3px;
                }
                
                .right{
                    
                    .subscribe-btn{
                        font-size:0;
                        
                        &:after{
                            content: "\f1ea";
                            font-family:"FontAwesome";
                            color:white;
                            font-size: 13px;
                        }
                    }
                    .search-box{
                        input{
                            width: 136px;
                        }
                    }
                }
                
                .left{
                    img{
                        height: 20px;
                        margin-left: 20px;
                        margin-top: 2px;
                        width: auto;
                    }
                }
            }
        }
        
        
        .bottom {
            padding:0 20px;
            .right {
                width:50px;

                #INDbtnTooltip {
                    top: 13px !important;
                    right: 128px !important;
                    z-index: 20;
                }
                
                #INDmenu-btn{
                    p{
                        display:none;
                    }
                }
                
            }

            .center{
                width: -moz-calc(100% - 100px);
                width: -webkit-calc(100% - 100px);
                width: calc(100% - 100px);

                ul{
                    li{
                        font-size: 13.5px;
                        color: #4296b4;
                        display: inline-block;
                        margin: 0 5px;
                        position: relative;
                        padding: 0 8px;
                        line-height: 58px;
                    }
                }
            }

            .left{
                width:50px;
            }
        }
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    html, body {
        min-width:0px !important;
    }
    
    .main-nav{
        display: none;
    }
    
    .mobile-nav{
        display: block;
        background:white;
        position: fixed;
        top:0;
        width: 100%;
        text-align: center;
        font-size: 0;
        z-index:500;
        
        
        .search-container{
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 9000;
            background: rgba(0, 0, 0, 0.7);
            right: 0;
            top: 0;
            display:none;
        }
        
        .close-search{
            font-size: 70px;
            position: fixed;
            top: 15px;
            right: 20px;
            color: white;
        }
        .search-box{
                display: inline-block;
                border: 4px solid #ebebeb;
                border-radius: 4px;
                vertical-align: middle;
                background: white;
                width: 90%;
                margin-top: 205px;
                
                input{
                    display: inline-block;
                    font-size: 29px;
                    border: 0;
                    color: #656565;
                    outline: 0;
                    width: 84%;
                    padding-right: 4px;
                    height: 90px;
                    vertical-align: middle;
                    @include transition(all 0.3s linear);
                }
                
                img{
                   width: 54px;
                    height: auto;
                    display: inline-block;
                    vertical-align: middle;
                    padding: 10px;
                }
            }
        
        .top{
            padding:20px 30px;
            box-sizing:border-box;
            border-bottom: 0;
            position: relative;
            height:91px;

            .logo{
                height: 50px;
                display: inline-block;
                vertical-align: middle;
                position: absolute;
                left: 50%;
                margin-left: -170.42px;
            }
        }
        
        .bottom{
            a{
                &:first-child{
                    .half{
                        border-left:1px solid white;
                    }
                }
            }
            .half{
                display: inline-block;
                color:$light-blue;
                font-size:24px;
                width:50%;
                background:#f4f7f9;
                padding:20px 0;
                box-sizing: border-box;
                
                &.active{
                    background:$light-blue;
                    color:white;
                }
            }
        }
        .search{
            img{
                position: absolute;
                right: 30px;
                top: 28px;
                height: 36px;
            }
        }
        
        .menu{
            i{
                position: absolute;
                left: 30px;
                top: 26px;
                font-size: 40px;
                color: #656565;
            }
        }
    }
    
    .mobile-menu{
        position:fixed;
        display: block;
        top:0;
        right:0;
        height:100vh;
        width:100%;
        z-index:600;
        padding-top:90px;
        pointer-events: none;
        font-size:0;
        right: 100%;
        @include transition(all 0.3s ease, background 0.4s linear);

        &.is-open{
            right:0;
        }
        .menu-wrapper{
            width:100%;
            height:100%;
            background:white;
            pointer-events: auto;
            overflow: auto;
        }
        
        .login{
            width:100%;
            overflow: hidden;
            
            .half{
                display: inline-block;
                width:50%;
                background:$dark-blue;
                border-left: 1px solid white;
                font-size:24px;
                color:white;
                height: 80px;
                line-height: 51px;
                padding: 14px 40px;
                text-align: center;
                vertical-align: top;
                box-sizing: border-box;
            }
            
            a{
                &:last-child{
                    .half{
                        background:$light-blue;
                        border:0;
                        line-height: 27.6px;
                    }
                }
            }
        }
        
        .prop{
            width:100%;
            font-size:24px;
            color:$grey;
            overflow: hidden;
            padding:0 30px;
            box-sizing: border-box;
            border-bottom:1px solid #d7d7d7;
            #INDmenu-btn {
                bottom: unset;direction: rtl; float: none; right: unset;                
            }
            #INDbtnTooltip {top: 13px!important; right: 214px!important;}
            .access{
                display:inline-block;
                text-decoration: underline;
            }
            
            .lang{
                float:left;
                p{
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 3px;
                    margin-left: 3px;
                }
                
                i{
                    vertical-align: middle;
                    position: relative;
                    top:-4px;
                }
            }
        }
        
        ul{
            list-style: none;
            padding:0;
            
            li{
                font-size:30px;
                color:$dark-blue;
                padding:25px;
                text-align: center;
                border-bottom:1px solid #d7d7d7;
                
                img{
                    height:50px;
                }
            }
        }
    }
    .content {
        padding-top: 156px;
    }
    
    .contact-section{
        background:#f6f6f6;

        .container{
            background:#f6f6f6;
            
            .contact-box{
                display:none;

            }
            
           .contact-btn{
                display: block;
                margin: 0 auto;
                margin-top: 5px;
                font-weight:$medium;
                color:white;
                text-decoration: none;
                padding: 25px 50px;
                font-size:24px;
                max-width: 100%;
                text-align: center;
                background:$light-blue;
                border-radius:4px;
                @include transition(all 0.3s ease, background 0.2s linear);
            }
        }
    }
    
    .logos-container{
        background:#f6f6f6;padding-bottom: 40px;
        .item{
            border:0;
            
            .logo{
                width: 230px;
                height: 110px;
            }
            
            h3{
                margin-top: 20px;
                font-size: 17px;
            }
        }
    }
    
    .login-bar{
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;

        .third{
            display:none;
            &.right{
                padding: 20px 0;
                float: right;
                width: 100%;
                float: none;
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                
                .login-btn{
                    padding: 25px 50px;
                    font-size: 24px;
                    height: 90px;
                    line-height: 40px;
                }
                
                .subscribe-btn{
                    padding: 10px 80px;
                    padding-top: 19px;
                    font-size: 24px;
                    height: 90px;
                    max-width: 340px;
                }
            }
            
            &.left{
                font-size: 40px;
                width: 100%;
                text-align: center;
                
                a{
                    margin: 0 20px;
                    text-align: center;
                }
            }
        }
    }
    
    .sitemap{
        display:none;
    }
    .sitemap-mobile{
        display:block;
        text-align: center;
        padding:20px 20px;
        border-bottom: 1px solid #dedede;
        margin-bottom:40px;

        h3{
            display:inline-block;
            color:$dark-blue;
            text-align: right;
            font-weight:$medium;
            font-size: 24px;
            padding: 15px 20px;
            box-sizing: border-box;
            padding-left: 60px;
            width: 46%;
            vertical-align: middle;
            margin-top: 0;
        }
    }
    
    .mobile-bottom{
        display:block;
        text-align: center;
        
        img{
            height:120px;
        }
        
        p{
            color:$light-blue;
            width: 100%;
            padding: 0 50px;
            font-size: 20px;
            font-weight: 400;
            margin: 40px 0;
            opacity: 0.7;
        }
    }
    
    .pop-thanks-wrapper{
        .thanks-pop{
                position: relative; width: 90%; text-align: center; right: 0; margin: 0 auto; top: 13%;
            .close-btn{ font-size: 40px;}
      }

}


}

.en{
    .main-nav .top .right {float: right; text-align: right;}
    .main-nav .top .left {float: left; text-align: left;}
    .logos-container .item {border-right: 1px solid #dedede;border-left: 0px solid #dedede;}
    .logos-container .item:last-of-type {border-right: 0px solid #dedede;}
    .sitemap .col {text-align: left;margin-right: 56px;margin-left: unset;}
    .login-bar .third.right {float: left; text-align: left;}
    .login-bar .third.left {float: right; text-align: right;}
    .contact-section .container .contact-box .form .field.spaced { margin-right: 8px; margin-left: unset;}
    .main-nav .bottom .right #INDmenu-btn {direction: ltr; float: none; left: 0;}
    .main-nav .bottom .right div .negishut p {padding-left: 6px;}
    .main-nav .bottom .right #INDbtnTooltip { left: 146px !important;}
    .main-nav .top .right .subscribe-btn {margin-right: 10px; margin-left: 10px;}
    .contact-section .container .contact-box .form select{background-position: 435px 50%;}
    @media screen and (max-width: 640px)
    {   .mobile-menu .login a:last-child .half {line-height: 54px;}
        .login-bar .third.right {float: none;text-align:center;}
        .login-bar .third.right .subscribe-btn { padding-top: 32px;}
        .login-bar .third.left {width: 100%;text-align: center;}
        .sitemap-mobile h3 { text-align: left; padding-right: unset; padding-left: 60px;} 
        .mobile-menu .prop .lang {float: right;}      
        .mobile-menu .prop #INDmenu-btn {left: 62px;}
        #INDmenu-btn {position: absolute;}
        .contact-section .container .contact-box .form select{background-position: 545px 50%;}
    }
}
